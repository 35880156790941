<template>
  <v-dialog v-model="dialog" max-width="800px" no-click-animation persistent>
    <v-card>
      <v-card-title>New Destination</v-card-title>

      <v-divider></v-divider>

      <v-card-text class="py-5">
        <v-row>
          <v-col cols="12" md="12">
            <v-form ref="form">
              <v-row>
                <v-col cols="12" md="10">
                  <v-text-field
                    :autofocus="!editedItem.id"
                    :error-messages="handleErrors($v.form.name)"
                    :value="editedItem.name"
                    @blur="$v.form.name.$touch()"
                    hint="Ensure the name of the destination is easily distinguishable"
                    label="Name"
                    ref="streetRef"
                    required
                    v-model.trim="$v.form.name.$model"
                    @keydown.enter.prevent
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <address-form
                  :multiple="false"
                  :readonly="address.readonly"
                  :showLatLong="true"
                  ref="aform"
                  required
                  v-model="$v.form.address.$model"
                ></address-form>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="primary" text @click="close(true)"> close </v-btn>
        <v-btn color="success" :disabled="$v.$invalid" @click="save()"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

import {
  GET_DESTINATIONS,
  GET_PROSPECTIVE_DESTINATIONS,
  SAVE_DESTINATION,
  DELETE_DESTINATIONS,
} from '@/store/modules/Destination/actions';

import { GET_STOPS } from '@/store/modules/Stop/actions';
import { handleErrors } from '@/util';

import AddressForm from '@/components/AddressForm.vue';
import AddressFormValidations from '@/models/AddressFormValidation.model';

const newDestination = {
  address: {},
  name: '',
};

export default {
  name: 'NewDestination',
  mixins: [validationMixin],
  components: { AddressForm },
  props: {
    destination: Object,
    tripRequest: Object,
    preventAutocomplete: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      address: {},
      autocompleteInitialized: false,
      dialog: false,
      editedItem: {},
      form: { ...newDestination },
      handleErrors,
    };
  },
  validations: {
    form: {
      address: AddressFormValidations,
      name: { required },
    },
  },
  methods: {
    ...mapActions('destination', [
      DELETE_DESTINATIONS,
      GET_DESTINATIONS,
      GET_PROSPECTIVE_DESTINATIONS,
      SAVE_DESTINATION,
    ]),
    ...mapActions('stop', [GET_STOPS]),
    async save() {
      try {
        this.$v.form.address.name.$model = this.$v.form.name.$model;

        const r = await this.saveDestination({ ...this.$v.form.$model, prospective: 1 });

        if (r && r.id) {
          this.$myalert.success('Destination saved');

          await this.getDestinations();
          await this.getProspectiveDestinations();
          await this.getStops();

          this.$emit('newDestinationCreated', r.id);
          this.close();
        }
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
    close(manual) {
      if (manual) this.$emit('newDestinationCreated', 0);

      this.editedItem = {};
      this.form = { ...newDestination };
      this.dialog = false;
    },
  },
  watch: {
    destination(value) {
      if (!value || !value.address) {
        return (this.form.address = {
          name: '',
          isPrimary: false,
          address: '',
          address2: '',
          city: '',
          state: '',
          zip: null,
          lng: null,
          lat: null,
        });
      }

      this.form.address = {
        isPrimary: false,
        address: '',
        address2: '',
        city: '',
        state: '',
        zip: null,
        lng: null,
        lat: null,
        ...value.address,
        name: '',
      };

      this.form.name = this.form.name || this.destination.name;
    },
  },
};
</script>

<style lang="scss"></style>
