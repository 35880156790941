<template>
  <div>
    <v-container fluid>
      <v-form class="full-width">
        <v-row align="baseline">
          <v-col cols="12" md="6">
            <v-row dense>
              <v-col cols="12" md="12">
                <span v-if="!tripRequest.status || tripRequest.status == -1" class="red--text">
                  <small>*Required</small>
                </span>

                <destination-autocomplete
                  :destinationFilters="tripRequestConfig.display.newDestination ? {} : { prospects: 0 }"
                  :hint="tripRequestConfig.labels.commonDestinations"
                  :loading="loadingDestinations"
                  :includePlaces="tripRequestConfig.display.newDestination"
                  :readonly="isReadonly"
                  :clearable="!isReadonly"
                  :rules="[() => !!tripRequest.destinationId || 'This field is required']"
                  :semesterId="tripRequest.semesterId"
                  @change="handleDestination"
                  @destinationChanged="handleNewDestination"
                  @internalLoading="(val) => (loadingDestinations = val)"
                  label="Main Destination"
                  outlined
                  persistent-hint
                  ref="destinationPicker"
                  required
                  v-model="tripRequest.destinationId"
                  @keydown.enter.prevent
                >
                  <template #item="{ on, attrs, item }">
                    <v-list-item v-on="on" v-bind="attrs">
                      <v-icon color="orange" left v-if="item.place_id">mdi-map-marker-plus</v-icon>
                      <v-icon color="green" left v-else>mdi-map-marker</v-icon>
                      <v-list-item-title>{{ item.text }}</v-list-item-title>
                    </v-list-item>
                  </template>

                  <template #append-item>
                    <v-list-item @click="createNewDestination()" v-if="tripRequestConfig.display.newDestination">
                      <v-list-item-title
                        >Having trouble finding an address? Click here create a new destination</v-list-item-title
                      >
                    </v-list-item>
                  </template>
                </destination-autocomplete>
              </v-col>

              <v-col cols="12" md="6">
                <v-btn
                  :disabled="isReadonly"
                  @click="addStopBack('location')"
                  color="primary"
                  v-show="showStopButtons"
                  width="100%"
                >
                  Add Location Stop
                </v-btn>
              </v-col>

              <v-col cols="12" md="6">
                <v-btn
                  :disabled="isReadonly"
                  @click="addStopBack('destination')"
                  color="primary"
                  width="100%"
                  v-show="showStopButtons"
                >
                  Add Destination Stop
                </v-btn>
              </v-col>

              <v-col cols="12" md="12" v-if="tripRequestConfig.display.additionalStops">
                <v-btn :disabled="isReadonly" @click="addStop()" color="primary" width="100%" v-show="showStopButtons">
                  Add Trip Stops
                </v-btn>
              </v-col>
            </v-row>
          </v-col>

          <trip-itinerary
            :tripRequest="tripRequest"
            @preventSubmit="$emit('preventSubmit', $event)"
            ref="tripItinerary"
          ></trip-itinerary>
        </v-row>

        <v-row v-if="customFormFields">
          <custom-form-field
            v-for="(cff, i) in customFormFields"
            :ref="cff.id"
            :key="i"
            :cff="cff"
            :value="tripRequest.customFormFields[cff.id]"
            :readonly="
              (!Number.isInteger(step) &&
                tripRequest.status == 1 &&
                readonly &&
                !tripRequest.permissions.canEditSiteAdmin) ||
              cannotModifyFields
            "
            @handleCFFInput="$emit('handleCFFInput', { cffId: cff.id, value: $event })"
          ></custom-form-field>
        </v-row>
      </v-form>
    </v-container>

    <new-destination
      :destination="destination"
      :tripRequest="tripRequest"
      @newDestinationCreated="setNewDestinationId"
      prevent-autocomplete
      ref="newDestination"
    ></new-destination>

    <destination-stop :tripRequest="tripRequest" ref="stopeditor"></destination-stop>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import CustomFormField from '@/views/TripRequestForm/CustomFormField.vue';
import NewDestination from '@/views/TripRequestForm/NewDestination.vue';

import DestinationAutocomplete from '@/components/DestinationAutocomplete.vue';
import DestinationStop from '@/components/DestinationStop.vue';
import TripItinerary from '@/components/TripItinerary.vue';

import { TRIP_STATUS } from '@/shared/common';
import { getBusinessName } from '@/util';

export default {
  name: 'StepDestination',
  inject: ['eventHub'],
  components: { NewDestination, DestinationStop, CustomFormField, DestinationAutocomplete, TripItinerary },
  props: {
    tripRequest: Object,
    customFormFields: Array,
    step: Number,
    readonly: Boolean,
  },
  data() {
    return {
      destination: {},
      loadingDestinations: false,
      stop: {},
      waypoints: (this.tripRequest.stops || [])
        .filter((stop) => stop.seq !== 0 && stop.seq !== this.tripRequest.stops.length - 1)
        .map((stop) => {
          let waypoint = `Waypoint ${stop.seq}`;
          return { [waypoint]: stop.name };
        }),
    };
  },
  computed: {
    ...mapGetters('config', ['tripRequestConfig']),
    ...mapGetters('destination', ['destinations', 'prospectiveDestinations']),
    ...mapGetters('location', ['locationsById']),
    ...mapGetters('stop', ['stops']),
    ...mapGetters('tripRequest', ['tripRequestsById']),
    ...mapGetters('user', ['me']),
    showStopButtons() {
      return typeof this.tripRequest.destinationId === 'number' && this.tripRequest.destinationId > 0;
    },
    existingStops() {
      return (this.tripRequest.stops || []).filter((stop) => !stop?.delete);
    },
    cannotModifyFields() {
      return (
        (!this.tripRequest.permissions.canEditPreApproval || !this.tripRequest.permissions.canEditMidApproval) &&
        !Number.isInteger(this.step) &&
        this.tripRequest.status !== TRIP_STATUS.DRAFT
      );
    },
    isReadonly() {
      return (
        (!Number.isInteger(this.step) &&
          this.readonly &&
          !this.tripRequest.permissions.canEditSiteAdmin &&
          !this.tripRequest.permissions.canEditApprover) ||
        this.cannotModifyFields
      );
    },
  },
  created() {
    this.eventHub.$on('validateStepDestination', (reset) => this.validate(reset));
  },
  mounted() {
    if (!this.tripRequest.stops || !this.tripRequest.stops.length) return;
    this.calculateRoutes();
  },
  beforeDestroy() {
    this.eventHub.$off('validateStepDestination');
  },
  methods: {
    createNewDestination() {
      this.destination = { name: '', address: {} };
      this.$refs.newDestination.dialog = true;
      this.$refs.destinationPicker.blur();
    },
    handleDestination() {
      if (!this.tripRequest.destinationId) return;
      if (!this.existingStops.length) this.makeStops();
      else this.replaceDestinationInStops();
    },
    handleNewDestination(value) {
      if (!value || !value.place_id) return;

      this.destination = { ...value, name: getBusinessName(value) };
      this.$refs.newDestination.dialog = true;
    },
    async setNewDestinationId(id) {
      if (!id) return;

      this.tripRequest.destinationId = id;
      this.$nextTick(() => this.$refs.destinationPicker.blur());

      if (!this.existingStops.length) this.makeStops();
      else this.replaceDestinationInStops();
    },
    async makeStops() {
      const locStop = this.stops.find((e) => e.schoolId == this.locationsById[this.tripRequest.locationId].schoolId);

      let dest = this.destinations.find((e) => e.id == this.tripRequest.destinationId);

      if (!dest) dest = this.prospectiveDestinations.find((e) => e.id == this.tripRequest.destinationId);

      const destStop = this.stops.find((e) => e.id == dest.stopId);

      this.tripRequest.stops.push(
        {
          id: '-1',
          tripRequestId: this.tripRequest.id,
          stopId: locStop.id,
          name: locStop.name,
          address: locStop.address,
          seq: 0,
          isLocation: true,
          simple: true,
        },
        {
          id: '-2',
          tripRequestId: this.tripRequest.id,
          stopId: destStop.id,
          name: destStop.name,
          address: destStop.address,
          seq: 1,
          isDestination: true,
          simple: true,
        },
        {
          id: '-3',
          tripRequestId: this.tripRequest.id,
          stopId: locStop.id,
          name: locStop.name,
          address: locStop.address,
          seq: 2,
          isLocation: true,
          simple: true,
        }
      );

      await this.calculateRoutes();
    },
    replaceDestinationInStops() {
      const oldIndex = this.existingStops.findIndex((e) => e.isDestination);

      const dest = [...this.destinations, ...this.prospectiveDestinations].find(
        (e) => e.id == this.tripRequest.destinationId
      );

      const destStop = this.stops.find((e) => e.id == dest.stopId);

      const newStop = {
        id: '-2',
        address: destStop.address,
        isDestination: true,
        name: destStop.name,
        simple: true,
        stopId: destStop.id,
        tripRequestId: this.tripRequest.id,
      };

      const stops = this.tripRequest.stops.map((s) => ({ ...s }));

      if (oldIndex !== -1) {
        const oldStop = stops[oldIndex];

        newStop.seq = oldStop.seq;
        oldStop.delete = true;

        stops[oldIndex] = newStop;
        stops.push(oldStop);
      } else {
        newStop.seq = stops.length;

        stops.push(newStop);
      }

      this.tripRequest.stops = stops;

      this.$nextTick(() => {
        this.calculateRoutes();
      });
    },
    addStop() {
      this.$refs.stopeditor.addAdditionalStop();
      this.$refs.stopeditor.dialog = true;
    },
    async addStopBack(type) {
      let uniqueId = -1;

      this.tripRequest.stops.forEach((stop) => (uniqueId = Math.min(uniqueId - 1, stop.id)));

      if (type == 'location') {
        const locStop = this.stops.find((e) => e.schoolId == this.locationsById[this.tripRequest.locationId].schoolId);

        this.tripRequest.stops.push({
          id: uniqueId,
          tripRequestId: this.tripRequest.id,
          stopId: locStop.id,
          name: locStop.name,
          address: locStop.address,
          seq: this.tripRequest.stops.length,
          isLocation: true,
          simple: true,
        });
      } else {
        const dest = [...this.destinations, ...this.prospectiveDestinations].find(
          (e) => e.id == this.tripRequest.destinationId
        );

        const destStop = this.stops.find((e) => e.id == dest.stopId);

        this.tripRequest.stops.push({
          id: uniqueId,
          tripRequestId: this.tripRequest.id,
          stopId: destStop.id,
          name: destStop.name,
          address: destStop.address,
          seq: this.tripRequest.stops.length,
          isDestination: true,
          simple: true,
        });
      }

      await this.calculateRoutes();
    },
    async calculateRoutes() {
      await this.$refs.tripItinerary.calculateRoutes();
    },
    validate(reset) {
      const required = ['destinationPicker'];

      if (!reset) {
        required.forEach((e) => this.$refs[e].validate(true));

        if (!this.customFormFields) return;

        this.customFormFields.forEach((e) => {
          if (e.required) this.$refs[e.id][0].cffValidation();
        });
      } else {
        required.forEach((e) => this.$refs[e].resetValidation());

        if (!this.customFormFields) return;

        this.customFormFields.forEach((e) => {
          if (e.required) this.$refs[e.id][0].cffValidation(true);
        });
      }
    },
  },
};
</script>

<style scoped>
.full-width {
  width: 100%;
}
</style>
