<template>
  <v-row>
    <v-col cols="12" md="7">
      <v-select
        :items="filteredFundingSources"
        :rules="[rules.required]"
        item-text="name"
        item-value="id"
        v-model="form.fundingSource"
        v-bind="fsInputProps"
        :loading="loading"
        @input="handleFSSelect"
      />
    </v-col>
    <v-col cols="12" md="5" v-if="selectedFundingSource">
      <template v-if="budgetCodeInputType === 'dropdown'">
        <BudgetCodeWithLocation
          :locationId="Number(currentTripRequest.locationId)"
          :fundingSource="selectedFundingSource"
          :useInputProps="useInputProps"
          :disabled="$attrs.disabled"
          v-model="form.budgetCode"
          :admin-only="false"
        />
      </template>
      <template v-else>
        <v-text-field
          label="Budget Code"
          :readonly="$attrs.readonly || !isBudgetCodeEditable"
          :disabled="$attrs.disabled"
          v-model="form.budgetCode"
          v-bind="bcInputProps"
        />
      </template>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { required } from '@/util/rules';
import { fundingSourceTypesMap } from '@/util/constants';
import { getBudgetCodeDescriptionWithLocation, inputProps } from '@/util';
import BudgetCodeWithLocation from '@/components/BudgetCode/BudgetCodeWithLocation.vue';

export default {
  props: {
    fundingSourceIndex: { type: Number, default: null },
    useInputProps: { type: Boolean, default: false },
    invoicePaymentFundingSources: { type: Array, default: null },
    editPaymentData: { type: Object, default: null },
  },
  components: { BudgetCodeWithLocation },
  data() {
    return {
      loading: false,
      rules: { required },
      form: { fundingSource: null, budgetCode: null },
      isInit: false,
      inputProps,
    };
  },
  computed: {
    ...mapGetters('fundingSource', ['fundingSources', 'filterFundingSources', 'fundingSourcesById']),
    ...mapGetters('location', ['schools', 'locationsById']),
    ...mapGetters('budgetCode', ['budgetCodesByLocation', 'budgetCodes']),
    ...mapGetters('config', ['budgetCodeConfig']),
    ...mapGetters('tripRequest', ['currentTripLocation', 'currentTripRequest']),
    hasTripFunding() {
      return this.tripFundingSource?.fundingSourceId > 0;
    },
    hasInvoicePayments() {
      return this.editPaymentData?.fundingSourceId >= 0;
    },
    tripFundingSource() {
      return this.currentTripRequest.fundingSources[this.fundingSourceIndex];
    },
    isBudgetCodeEditable() {
      if (this.selectedFundingSource?.type !== fundingSourceTypesMap.editableBlank) return false;

      return (
        this.selectedFundingSource?.type === fundingSourceTypesMap.editableBlank &&
        this.selectedFundingSource?.codeIsEditable
      );
    },
    budgetCodeInputType() {
      if (!this.selectedFundingSource) return null;

      const isSingleBudgetCode = [
        fundingSourceTypesMap.singleBudgetCode,
        fundingSourceTypesMap.singleBudgetCodeAllotments,
      ].includes(this.selectedFundingSource.type);
      const hasNoBudgetCode = !this.selectedFundingSource.budgetCodeId;

      if (isSingleBudgetCode && hasNoBudgetCode) return 'dropdown';
      if (this.selectedFundingSource.type === fundingSourceTypesMap.multipleBudgetCodes) return 'dropdown';

      return 'text';
    },
    fsInputProps() {
      let props = {
        menuProps: { bottom: true, offsetY: true },
        label: this.$attrs.fsLabel || 'Funding Source',
        readonly: this.$attrs.readonly,
        hint: this.$attrs.hint,
        persistentHint: true,
        disabled: this.$attrs.disabled,
      };

      if (this.useInputProps) {
        props = { ...props, ...inputProps };
      }

      return props;
    },
    bcInputProps() {
      return this.useInputProps ? inputProps : {};
    },
    filteredFundingSources() {
      const items = [];
      let selected = { fundingSourceId: null };
      const currentFundingSourceId = this.editPaymentData?.fundingSourceId;

      const currentInvoicePaymentFundingSources = this.invoicePaymentFundingSources || [];
      const existingFundingSourceIds = new Set(currentInvoicePaymentFundingSources.map((fs) => fs.fundingSourceId));

      if (currentFundingSourceId) {
        existingFundingSourceIds.delete(currentFundingSourceId);
      }

      if (this.hasTripFunding) selected = this.currentTripRequest.fundingSources[this.fundingSourceIndex];

      items.push(
        ...this.filterFundingSources({
          tripTypeId: this.currentTripRequest.tripTypeId,
          locationId: this.currentTripRequest.locationId,
          existingFundingSourceIds,
          selected: selected.fundingSourceId,
        })
      );

      // No need to add None funding source from option - uncomment if needed again
      // if (this.$attrs.addNoneFundingSource) items.push({ name: 'None', id: 0 });

      return items;
    },
    selectedFundingSource() {
      if (!this.form.fundingSource) return null;

      return this.fundingSourcesById[this.form.fundingSource];
    },
    filteredBudgetCodes() {
      let budgetCodes = [];

      if (!this.selectedFundingSource) return [];
      if (!this.currentTripRequest?.locationId) return [];

      const { type } = this.selectedFundingSource;
      const locationCode = this.currentTripLocation?.code;

      switch (type) {
        case fundingSourceTypesMap.singleBudgetCode:
        case fundingSourceTypesMap.singleBudgetCodeAllotments:
          budgetCodes.push(
            getBudgetCodeDescriptionWithLocation({
              fundingSource: this.selectedFundingSource,
              locationCode,
              budgetCodeConfig: this.budgetCodeConfig,
              budgetCodes: this.budgetCodes,
            })
          );
          break;
        case fundingSourceTypesMap.multipleBudgetCodes:
          budgetCodes = this.handleTypeThree(locationCode);
          break;
        case fundingSourceTypesMap.editableBlank:
          budgetCodes.push(this.selectedFundingSource.budgetCode);
          break;
        default:
          break;
      }

      return budgetCodes;
    },
  },
  methods: {
    ...mapActions('budgetCode', ['getBudgetCodes']),
    ...mapActions('fundingSource', ['saveFundingSource']),
    emitFundingSourceValue() {
      if (this.isInit) return;

      const bc = this.form.budgetCode;

      this.$emit('input', {
        tripRequestId: this.currentTripRequest.id,
        fundingSourceId: this.form.fundingSource,
        budgetCode: bc?.text || bc,
        budgetCodeId: bc?.value || 0,
      });
    },
    handleFSSelect() {
      const fs = this.selectedFundingSource;

      if (!fs) {
        this.form.budgetCode = null;
        return;
      }

      if (fs.type === fundingSourceTypesMap.editableBlank) {
        this.form.budgetCode = fs.budgetCode ?? null;
        return;
      }

      if (fs.budgetCodeId && this.filteredBudgetCodes.length > 0) {
        this.form.budgetCode = this.filteredBudgetCodes[0];
        return;
      }

      this.form.budgetCode = null;
    },
    handleTypeThree(locationCode) {
      let budgetCodes = [];

      const index = this.budgetCodeConfig.findIndex((config) => config.locationDependent === true);
      if (index !== -1) {
        budgetCodes = this.budgetCodes.filter((bc) => {
          const codes = Object.values(bc.code);
          const codesString = codes.join(' ');
          return !codesString.includes('?') && +codes[index] === +locationCode.toString();
        });
      }

      return budgetCodes;
    },
  },
  async mounted() {
    try {
      this.isInit = true;
      this.loading = true;
      await this.getBudgetCodes();

      if (this.hasTripFunding) {
        this.form.fundingSource = this.tripFundingSource.fundingSourceId;
        this.form.budgetCode =
          this.budgetCodeInputType !== 'dropdown'
            ? this.tripFundingSource.budgetCode
            : {
                text: this.tripFundingSource.budgetCode,
                value: this.tripFundingSource.budgetCodeId,
              };
      }
      if (this.hasInvoicePayments) {
        this.form.fundingSource = this.editPaymentData.fundingSourceId;
        this.form.budgetCode = this.editPaymentData.budgetCode;
        this.form.budgetCode =
          this.budgetCodeInputType !== 'dropdown'
            ? this.editPaymentData.budgetCode
            : {
                text: this.editPaymentData.budgetCode,
                value: this.editPaymentData.budgetCodeId,
              };
      }
    } catch (e) {
      this.$myalert.error('An error occurred while fetching budget codes.', true);
    } finally {
      this.loading = false;
      this.isInit = false;
      this.emitFundingSourceValue();
    }
  },
  watch: {
    'form.fundingSource': {
      handler(val) {
        this.emitFundingSourceValue();
      },
    },
    'form.budgetCode': {
      handler(val) {
        this.emitFundingSourceValue();
      },
    },
  },
};
</script>
